import {APIService} from "@/services/api";
import i18n from "@/plugins/i18n";

export const uniqueNewsUrlValidator = (value, field, model) => {
  return new Promise(async (resolve, reject) => {
    if (value) {
      try {
        const news = await APIService.get(`news?filter=url||$eq||${value}`);
        if (news.length > 0) {
          if (news[0].id !== model.id) {
            resolve([i18n.t('UrlAlreadyExists', {url: value})]);
          }
        } else {
          resolve();
        }
      } catch (e) {
        console.error(e)
        reject()
      }
    }
  });
};
